import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [sort, setSort] = useState({
    column: null,
    direction: "ascending"
  });

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    const response = await axios.get("https://server.dms.co.id:5000/products");
    setProducts(response.data);
  };

  const deleteProduct = async (productId) => {
    await axios.delete(`https://server.dms.co.id:5000/products/${productId}`);
    getProducts();
    closeModal();
  };

  const openModal = (product) => {
    setProductToDelete(product);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const sortTable = (column) => {
    let direction = "ascending";
    if (sort.column === column && sort.direction === "ascending") {
      direction = "descending";
    }
    setSort({ column, direction });
  };

  // eslint-disable-next-line no-unused-vars
  const sortedProducts = products
    .sort((a, b) => {
      if (sort.direction === "ascending") {
        if (a[sort.column] < b[sort.column]) {
          return -1;
        }
        if (a[sort.column] > b[sort.column]) {
          return 1;
        }
      } else {
        if (a[sort.column] < b[sort.column]) {
          return 1;
        }
        if (a[sort.column] > b[sort.column]) {
          return -1;
        }
      }
      return 0;
    });

  return (
    <div>
      <h1 className="title">Products</h1>
      <h2 className="subtitle">List of Products</h2>
      <Link to="/products/add" className="button is-link mb-2">
        Add New
      </Link>
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th
              onClick={() => sortTable("id")}
              style={{ cursor: "pointer" }}
            >
              No
            </th>
            <th
              onClick={() => sortTable("name")}
              style={{ cursor: "pointer" }}
            >
              Product Name
            </th>
            <th
              onClick={() => sortTable("price")}
              style={{ cursor: "pointer" }}
            >
              Price
            </th>
            <th
              onClick={() => sortTable("user.name")}
              style={{ cursor: "pointer" }}
            >
              User
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => (
            <tr key={product.uuid}>
              <td>{index + 1}</td>
              <td>{product.name}</td>
              <td>{product.price}</td>
              <td>{product.user.name}</td>
              <td>
                <Link
                  to={`/products/edit/${product.uuid}`}
                  className="button is-small is-info"
                >
                  Edit
                </Link>
                <button
                  onClick={() => openModal(product)}
                  className="button is-small is-danger"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={`modal ${modalOpen ? "is-active" : ""}`}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Confirm Delete</p>
            <button
              onClick={closeModal}
              className="delete"
              aria-label="close"
            ></button>
          </header>
          <section className="modal-card-body">
            Are you sure you want to delete {productToDelete?.name}? This action cannot be undone.
          </section>
          <footer className="modal-card-foot">
            <button onClick={closeModal} className="button">
              Cancel
            </button>
            <button
              onClick={() => deleteProduct(productToDelete?.uuid)}
              className="button is-danger"
            >
              Delete
            </button>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default ProductList;